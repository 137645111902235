//获取活动列表
const getActivityListUrl = `/gateway/hc-serve/manageapi/activity/activityList`;
//获取活动详情
const activityDetail = `/gateway/hc-serve/manageapi/activity/activityDetail`;
//增加|编辑活动
const activityEdit = `/gateway/hc-serve/manageapi/activity/activityEdit`;
//增加|编辑活动反馈
const activityFeedbackEdit = `/gateway/hc-serve/manageapi/activity/activityFeedbackEdit`;
//活动取消
const activityCancel = `/gateway/hc-serve/manageapi/activity/activityCancel`;
//取消活动报名
// const cancelActivityApply = `/gateway/hc-serve/manageapi/activity/cancelActivityApply`;
const cancelActivityApply = `/gateway/hc-serve/manageapi/activity/cancelActivityApplications`;
//手机号查询用户
const getUserDetailURL = `/gateway/blade-user/user/detail`;
// 运营人员替别人活动报名
const getActivityApplyForUserURL =
  "/gateway/hc-serve/manageapi/activity/activityApplyForUser";

//活动审核
const activityAudit = `/gateway/hc-serve/manageapi/activity/activityAudit`;

//获取活动参与人列表
const activityApplicantsList = `/gateway/hc-serve/manageapi/activity/activityApplicantsList`;

//积分规则列表
const activityPointSettingList = `/gateway/hc-serve/manageapi/activity/activityPointSettingList`;

//活动申请列表
const activityApplyList = `/gateway/hc-serve/manageapi/activity/activityApplyList`;

//活动申请详情
const activityApplyInfo = `/gateway/hc-serve/manageapi/activity/activityApplyInfo`;
//公益捐赠审核
const donateAudit = `/gateway/hc-serve/manageapi/donate/donateAudit`;
//活动参与人导出
const exportApplicantsUrl = `/gateway/hc-serve/manageapi/activity/exportActivityApplicantsListToExcel`;
//场地列表
const areaListUrl = `/gateway/hc-serve/manageapi/activity/reservationPlaceList`;

// 志愿者列表
const getVolunteerListUrl = `/gateway/hc-serve/manageapi/volunteer/volunteerList`;
// 拉黑志愿者
const blacklistVolunteerUrl = `/gateway/hc-serve/manageapi/volunteer/blacklistVolunteer`;
// 删除志愿者
const deleteVolunteerUrl = `/gateway/hc-serve/manageapi/volunteer/deleteVolunteer`;
// 志愿活动签到详情接口
const volunteerSignInList = `/gateway/hc-serve/manageapi/volunteer/volunteerSignInDetails`;
// 编辑积分
const integrateCompileUrl = `/gateway/hc-serve/manageapi/volunteer/integrateCompile`;
//获取建筑列表
const getBuildListURL = `/gateway/hc-space/space/getSpaceTreeList`;
//获取话题列表
const getTopicListURL = `/gateway/hc-neighbour/topic/getTopicList`;
//获取排行版列表
const getCommentPraiseRankingListURL =
  "/gateway/hc-neighbour/topic/getCommentPraiseRankingList";
//获取积分商城详情
const getTopicDetailURL = `/gateway/hc-neighbour/topic/getTopicInfoById`;
//新增话题
const addTopicURL = `/gateway/hc-neighbour/topic/addTopic`;
//更新话题
const updateTopicURL = `/gateway/hc-neighbour/topic/updateTopic`;
// //话题类型
// const getInitInfoURL = "/gateway/hc-neighbour/talent/getInitInfo";
//话题姓名
const getTalentUserListURL = `/gateway/hc-neighbour/talent/getTalentUserList`;
//详情
const getTopicInfoByIdURL = `/gateway/hc-neighbour/topic/getTopicInfoById`;

//获取积分商城订单列表(导出用)
const PointsMallExportOrderListUrl = `/placeApi/mng/pointsMallOrder/exportOrderList`;
//积分兑换订单-完成订单
const PointsMallOrderCompleted = `/placeApi/mng/pointsMallOrder/orderCompleted`;

//获取园区列表
const getCommunityListUrl = `/gateway/hc-space/space/community`;
// 发送积分
const doTopicURL = "/gateway/hc-neighbour/dailyPerf/doTopic";
//删除创业达人
const deleteTopicURL = "/gateway/hc-neighbour/topic/deleteTopic";
// 队伍管理列表接口
const getUserSubjectAuditInfoListUrl = `/gateway/hc-govern/manageapi/votehall/getUserSubjectAuditInfoList`;
// 队伍管理审核接口
const examineUserSubjectAuditInfoUrl = `/gateway/hc-govern/manageapi/votehall/examineUserSubjectAuditInfo`;
// 一事一议一签列表查询
const getVoteHallEventListUrl = `/gateway/hc-govern/manageapi/votehall/getVoteHallEventList`;
// 一事一议一签审核保存
const examineVoteHallEventUrl = `/gateway/hc-govern/manageapi/votehall/examineVoteHallEvent`;
// 新增提议
const proposeEventUrl = `/gateway/hc-govern/manageapi/votehall/proposeEvent`;
// 删除我的提议
const deleteMyProposeEventUrl = `/gateway/hc-govern/miniapi/votehall/deleteMyProposeEvent`;
// 详情接口
const getVoteHallEventDetailUrl = `/gateway/hc-govern/manageapi/votehall/getVoteHallEventDetail`;
export {
  getVoteHallEventDetailUrl,
  deleteMyProposeEventUrl,
  proposeEventUrl,
  examineVoteHallEventUrl,
  getVoteHallEventListUrl,
  examineUserSubjectAuditInfoUrl,
  getUserSubjectAuditInfoListUrl,
  getActivityListUrl,
  activityDetail,
  activityEdit,
  cancelActivityApply,
  getUserDetailURL,
  getActivityApplyForUserURL,
  getCommunityListUrl,
  activityApplicantsList,
  activityCancel,
  activityPointSettingList,
  activityFeedbackEdit,
  activityAudit,
  activityApplyList,
  activityApplyInfo,
  donateAudit,
  exportApplicantsUrl,
  areaListUrl,
  getVolunteerListUrl,
  blacklistVolunteerUrl,
  deleteVolunteerUrl,
  volunteerSignInList,
  integrateCompileUrl,
  getBuildListURL,
  getTopicListURL,
  getTopicDetailURL,
  addTopicURL,
  updateTopicURL,
  getCommentPraiseRankingListURL,
  getTalentUserListURL,
  getTopicInfoByIdURL,
  PointsMallExportOrderListUrl,
  PointsMallOrderCompleted,
  doTopicURL,
  deleteTopicURL,
};
